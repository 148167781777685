import { Link } from "gatsby";
import React from "react";
import logo from "../../images/logo.png";
import TrialButton from "./../trialButton/trialButton";
import * as s from "./header.module.css";
import MobileNav from "./mobile-nav";

const Header = ({ scrollToForm }) => {
  let menuItems = [
    {
      title: "HOW IT WORKS",
      items: [
        // {
        //     title: 'Why ORDERS.CO',
        //     to: '/WhyOrdersCo',
        // },
        {
          title: "Customer Stories",
          to: "/customer-stories/",
        },
        {
          title: "Independent Restaurants",
          to: "/independent-restaurants/",
        },
        {
          title: "Chain Restaurants",
          to: "/chain-restaurants/",
        },
        {
          title: "Franchise",
          to: "/franchise/",
        },
        {
          title: "Ghost Kitchen",
          to: "/ghost-kitchen/",
        },
        {
          title: "Q-commerce",
          to: "/q-commerce/",
        },
        {
          title: "Virtual Concept",
          to: "/virtual-concept/",
        },
      ],
    },
    {
      title: "PRODUCT",
      items: [
        {
          title: "Features Overview",
          to: "/features-overview/",
        },
        {
          title: "Menu Management",
          to: "/menu-management/",
        },
        {
          title: "Orders Consolidation",
          to: "/orders-consolidation/",
        },
        {
          title: "Custom Ordering Website",
          to: "/custom-ordering-website/",
        },
        {
          title: "QR Menu Maker",
          to: "/qr-menu-maker/",
        },
        {
          title: "Integrations",
          to: "/integrations/",
        },
        {
          title: "Reporting",
          to: "/reporting/",
        },
        // {
        //     title: 'Orders.co application',
        //     to: '/',
        // },
      ],
    },
    {
      title: "WHO WE ARE",
      items: [
        {
          title: "About Us",
          to: "/about-us/",
        },
        // {
        //     title: 'Help Desk',
        //     to: '/',
        // },
        {
          title: "Contact Us",
          to: "/contact-us/",
        },
      ],
    },
    {
      title: "RESOURCES",
      items: [
        {
          title: "Blog",
          to: "/blog/",
        },
        // {
        //   title: "Newsroom",
        //   to: "/newsroom/",
        // },
        // {
        //     title: 'E-book',
        //     to: '/',
        // },
        {
          title: "FAQ",
          to: "/frequently-asked-questions/",
        },
      ],
    },
    {
      title: "PRICING",
      to: "/pricing/",
    },
    {
      title: "LOGIN",
      to: "https://partners.orders.co/login",
      target: "_blank",
    },
  ];
  // console.log(menuItems);

  return (
    <>
      <header className={s.header}>
        <div className="container">
          <div className="d-flex justify-content-between">
            <Link to="/">
              <img
                alt="logo"
                width={165}
                height={40}
                src={logo}
                loading="eager"
                className="d-none d-xl-block"
              />
              <img
                alt="logo-mobile"
                width={132}
                height={32}
                src={logo}
                loading="eager"
                className="d-xl-none"
              />
            </Link>
            <div className="d-none d-xl-flex align-items-center">
              <nav className={`d-flex align-items-center`}>
                {menuItems.map((mi, i) => {
                  let hasSubMenu = !!mi.items && mi.items.length > 0;
                  return (
                    <div
                      key={`header-menu-item-${i}`}
                      className={`mr-3 ${s.menuItem} ${
                        hasSubMenu ? s.hasSubmenu : ""
                      }`}
                    >
                      {hasSubMenu && (
                        <>
                          <span>{mi.title}</span>
                          <div className={`${s.subMenu}`}>
                            {mi.items.map((smi, j) => (
                              <div
                                key={`header-submenu-item-${i}-${j}`}
                                className={`${s.menuItem}`}
                              >
                                {!!smi.to && (
                                  <Link to={smi.to}>{smi.title}</Link>
                                )}
                                {!smi.to && <span>{smi.title}</span>}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      {!hasSubMenu && !!mi.to && (
                        <>
                          {!!mi.target && mi.target === "_blank" && (
                            <a href={mi.to} rel="noreferrer" target="_blank">
                              {mi.title}
                            </a>
                          )}
                          {!mi.target && <Link to={mi.to}>{mi.title}</Link>}
                        </>
                      )}
                    </div>
                  );
                })}
              </nav>
              <a
                href="tel:+18184522822"
                className={`bg-green-alt ${s.phoneButton}`}
              >
                Call Now
              </a>
              <TrialButton
                scrollToForm={scrollToForm}
                classes={["bg-orange cursor-pointer", s.trialButton]}
              >
                GET STARTED FOR FREE
              </TrialButton>
            </div>
          </div>
        </div>
        <div className="d-xl-none">
          <MobileNav menuItems={menuItems} />
        </div>
      </header>
    </>
  );
};

export default Header;
