import React from "react";
import * as s from "./subscribeBlock.module.css";
import axios from "axios";
import { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

const config = {
    baseURL: "https://webhook.site/427d7f1c-5c86-498c-b25a-4f5f31afd6a2",
    headers: { "Content-Type": "application/json" },
    method: "post",
    timeout: 3000,
};
const instance = axios.create(config);

const SubscribeBlock = () => {
    const form = useRef();

    const [fullName, setFullName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [errors, setErrors] = React.useState({
        fullName: null,
        email: null,
    });
    const [sending, setSending] = React.useState(false);
    const [messageSent, setMessageSent] = React.useState(false);


    const submitForm = (event) => {
        event.preventDefault();
        if (isValidForm()) {
            setSending(true);
            const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
            const lines = [];
            try {
                for (var x in queryParams) {
                    lines.push(`*${x}:* ${queryParams[x]}`)
                }
            } catch (e) { }

            const payloadForm = {
                event: "subscriber",
                source: "orders.co",
                utm_source: queryParams?.utm_source || "",
                name: !!fullName ? fullName : "",
                email: !!email ? email : "",
            };
            const payload = {
                ...payloadForm, details: lines.join("\n")
            }

            instance
                .request({ data: JSON.stringify(payload) })
                .then(function (response) {
                    // handle success
                    console.log("Subscribe Form Was Submitted Successfully");
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    window.dataLayer.push({ ...payloadForm });
                    setFullName("");
                    setEmail("");
                    setErrors({
                        fullName: null,
                        email: null,
                    });
                    setMessageSent(true);
                    // always executed
                });
        }
    }

    const isValidForm = () => {
        let isValid = true;
        let newErrors = {
            fullName: null,
            email: null,
        };

        if (!fullName?.length) {
            isValid = false;
            newErrors.fullName = 'Please enter Your Name';
        }

        if (!isEmailValid(email)) {
            isValid = false;
            newErrors.email = 'Please enter a valid Email';
        }

        setErrors(newErrors);
        return isValid;
    }

    const isEmailValid = (email) => {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }

    return (
        <div className={`${s.formBox}`}>
            <div className="container">
                {!!messageSent ?
                    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                        <StaticImage
                            placeholder="none"
                            loading="eager"
                            alt="thank_you"
                            src="../../images/thank_you.png"
                        />
                        <div className={`${s.thankYou} text-center`}>
                            <span>
                                Thank you!
                                <br /> You are Subscribed!
                            </span>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className={`${s.formBoxTitle}`}>Subscribe to <br /> our <strong>newsletter</strong></h3>
                        </div>
                        <div className="col-lg-6">

                            <form onSubmit={submitForm}>
                                <input type="hidden" name="form_type" value="subscribe_form" />
                                <input type="text" placeholder="Full Name" name="name" className={`${null != errors.fullName ? s.invalid : ''}`}
                                    value={fullName} onChange={e => setFullName(e.target.value)} required />
                                <br />
                                <input type="email" placeholder="Email" name="email" className={`${null != errors.email ? s.invalid : ''}`}
                                    value={email} onChange={e => setEmail(e.target.value)} required />
                                <br />
                                <button type="submit" disabled={sending}>Subscribe</button>
                            </form>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default SubscribeBlock;
