import React from "react";
import Header from "./header/header";
import FooterHome from "./home/footer/footer";

const LayoutPost = ({ children }) => {

  return (
    <>
      <Header />
      <main>{children}</main>
      <FooterHome />
    </>
  );
};

export default LayoutPost;
